import React, { useState } from 'react';

const Feedback = ({ questions, onSubmit }) => {
  const [responses, setResponses] = useState(
    questions.map((question, index) => ({
      name: `Feedback ${index + 1}`,
      value: ''
    })) // Initialize local state as an array of objects
  );
  const [confirmationMessage, setConfirmationMessage] = useState('');

  const handleInputChange = (index, value) => {
    const updatedResponses = [...responses];
    updatedResponses[index] = {
      ...updatedResponses[index],
      value
    };
    setResponses(updatedResponses);
  };

  const handleSubmit = () => {
    // Convert responses to the desired format
    const formattedResponses = responses.reduce((acc, response) => {
      acc[response.name] = response.value;
      return acc;
    }, {});
    console.log('Formatted responses:', formattedResponses);

    // Submit the formatted responses to the parent
    onSubmit(formattedResponses);

    // Clear the input fields
    setResponses(
      questions.map((question, index) => ({
        name: `Feedback ${index + 1}`,
        value: ''
      }))
    );

    // Display a confirmation message
    setConfirmationMessage('Thank you for your feedback!');
  };

  return (
    <div className="welcome-container">
      <h5>We'd love your feedback!</h5>
      {questions &&
        questions.map((question, index) => (
          <div key={index} className="mb-3">
            <label htmlFor={`feedback-${index}`}>{question}</label>
            <textarea
              id={`feedback-${index}`}
              name={`Feedback ${index + 1}`}
              value={responses[index].value}
              onChange={e => handleInputChange(index, e.target.value)}
              className="form-control"
              rows="4"
            />
          </div>
        ))}
      <button className="btn btn-primary mt-3" onClick={handleSubmit}>
        Submit
      </button>
      {confirmationMessage && (
        <div className="alert alert-success mt-3" role="alert">
          {confirmationMessage}
        </div>
      )}
    </div>
  );
};

export default Feedback;
